import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferQuery } from '@/utils/ts-rest';

export type CreatePlantProtectionProductServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createPlantProtectionProductService = ({
  tsRestClient
}: CreatePlantProtectionProductServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.plantProtectionProduct.all>) {
      return apiHandler(tsRestClient.plantProtectionProduct.all, {
        query
      });
    },

    getById(id: string) {
      return apiHandler(tsRestClient.plantProtectionProduct.byId, {
        params: { id }
      });
    }
  };
};
